<script>
import { mapActions } from "vuex";
import { imageHandler } from "../../services/errors";
import { backendUrl } from "../../services/apiReq";

export default {
  data() {
    return {
      items: [],
      itemDetails: [],
      currentIndex: 0,
      translateValue: 0,
      itemWidth: 300,
      timer: null,
      touchStartX: 0,
      touchEndX: 0,
      itemsPerPage: 1,
    };
  },
  async mounted() {
    let res = await this.get({
      tableName: "ofertas",
      perPage: 999,
      page: 1,
    });
    this.items = res?.data?.data;
    if (!this.items) return;

    res = await this.get({
      tableName: "products",
      perPage: 999,
      page: 1,
      search: {
        multiple: {
          queries: [
            {
              whereIn: {
                param: "codigo",
                values: this.items.map((x) => x?.codigo_nombre),
              },
            },
          ],
        },
      },
    });
    this.itemDetails = res?.data;
    if (!this.itemDetails) return;

    this.updateItemsPerPage();
    window.addEventListener("resize", this.updateItemsPerPage);

    this.startAutoSlide();
  },
  methods: {
    backendUrl() {
      return backendUrl;
    },
    imageHandler,
    ...mapActions(["get"]),
    prev() {
      // Lógica mejorada para no sobrepasar los límites
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.translateValue += this.itemWidth;
      } else {
        this.currentIndex = this.items.length - this.itemsPerPage;
        this.translateValue = -(this.itemWidth * this.currentIndex);
      }
      this.resetAutoSlide();
    },
    next() {
      if (this.currentIndex < this.items.length - this.itemsPerPage) {
        this.currentIndex++;
        this.translateValue -= this.itemWidth;
      } else {
        this.currentIndex = 0;
        this.translateValue = 0;
      }
      this.resetAutoSlide();
    },
    goTo(producto) {
      location.href = `/itemdetail?producto=${producto?.codigo}`;
    },
    updateItemsPerPage() {
      const containerWidth = this.$refs.carousel.clientWidth;
      this.itemWidth = 300;

      this.itemsPerPage = Math.floor(containerWidth / this.itemWidth);

      if (this.currentIndex > this.items.length - this.itemsPerPage) {
        this.currentIndex = this.items.length - this.itemsPerPage;
        this.translateValue = -(this.itemWidth * this.currentIndex);
      }
    },
    startAutoSlide() {
      this.timer = setInterval(this.next, 5000);
    },
    resetAutoSlide() {
      clearInterval(this.timer);
      this.startAutoSlide();
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleSwipeGesture();
    },
    handleSwipeGesture() {
      if (this.touchEndX < this.touchStartX) {
        this.next();
      }
      if (this.touchEndX > this.touchStartX) {
        this.prev();
      }
    },
  },
  computed: {
    Items() {
      return this.items?.map((x) => {
        let detail = this.itemDetails?.data?.find(
          (y) => y?.codigo?.trim() === x?.codigo_nombre
        );
        x = {
          ...x,
          ...detail,
        };
        x.precio_final = (
          x.precio - (x.descuento * x.precio) / 100
        )?.toFixed(2);
        return x;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener("resize", this.updateItemsPerPage);
  },
};
</script>

<template>
  <div
    class="carousel"
    ref="carousel"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div
      class="carousel-container"
      :style="{ transform: `translateX(${translateValue}px)` }"
    >
      <div
        title="Ofertas La Fortaleza"
        v-for="(item, index) in Items"
        :key="index"
        class="carousel-item"
        @click="goTo(item)"
      >
        <img
          :src="`${backendUrl()}/files/2/${item?.codigo_nombre?.trim()}.png`"
          @error="imageHandler($event)"
          class="carousel-img"
          alt="Producto en Oferta"
        />
        <div class="item-content">
          <div class="item-description">
            {{ item?.nombre }}
            <br />
            AR$ {{ item?.precio }}
            <br />
            {{ item?.descuento }} % OFF
            <br />
            Precio final: AR$ {{ item?.precio_final }}
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-button prev" @click="prev">&lt;</button>
    <button class="carousel-button next" @click="next">&gt;</button>
  </div>
</template>

<style scoped>
.carousel {
  margin: auto;
  margin-top: 2em;
  position: relative;
  overflow: hidden;
  width: 90%;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  width: 300px;
  height: 400px;
  margin-right: 10px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}

.item-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 500;
  padding: 10px;
  box-sizing: border-box;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 0.9em;
  text-align: center;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e5562f;
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

@media only screen and (max-width: 758px) {
  .carousel-item {
    width: 250px;
    height: 350px;
  }
}

@media only screen and (max-width: 458px) {
  .carousel-item {
    width: 230px;
    height: 320px;
  }
}

@media only screen and (max-width: 360px) {
  .carousel {
    width: 95%;
  }
}
</style>
