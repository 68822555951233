<script setup>
  import {reactive} from "vue";
  import {useStore} from "vuex";

  const store = useStore();
  const form = reactive({});
  const sendForm = async () => {
    console.log(form)
    if (!form.fullname || !form?.email || !form?.number || !form?.descripcion ) {
      alert("Debe completar todos los campos");
      return;
    }

    await store.dispatch("sendContact", {
      form,
    })
  }
</script>


<template>
    <body class='body-contact'>
    <div class="contact-section">
      <span class="big-circle"></span>
      <div class="form__content">
        <div class="contact-info">
          <h3 class="title">Mantengámonos en contacto</h3>
          <p class="text">
            No dudes en ponerte en contacto con nosotros a través del formulario que se encuentra a continuación. Nuestro equipo estará encantado de atenderte y te responderemos lo antes posible. Tambien puedes contactarnos por correo electrónico o teléfono.
          </p>

          <div class="info">
            <div class="information">
              <ion-icon name="location-outline"></ion-icon>
              <p>Av. Eva Perón 4276 - Fcio. Varela</p>
            </div>
            <div class="information">
              <ion-icon name="mail-outline"></ion-icon>
              <p>piresmarcelo7@hotmail.com</p>
            </div>
            <div class="information">
              <ion-icon name="call-outline"></ion-icon>
              <p>+54 9 11 2834-7514</p>
            </div>
          </div>

          <div class="social-media">
            <p>Conecta con nosotros:</p>
            <div class="social-icons">
              <a href="https://www.facebook.com/Dist.LFortaleza/" target="_blank" aria-label="Facebook La Fortaleza">
                <ion-icon name="logo-facebook"></ion-icon>
              </a>
              <a href="https://www.whatsapp.com/catalog/5491128347514/?app_absent=0" target="_blank" aria-label="Whatsapp La Fortaleza">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </a>
              <a href="https://www.instagram.com/dist.lfortaleza/" target="_blank" aria-label="Instagram La Fortaleza">
                <ion-icon name="logo-instagram"></ion-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="contact-form">
          <span class="circle one"></span>
          <span class="circle two"></span>
          <form @submit.prevent="" class="contact__form" autocomplete="off">
            <h3 class="title">Contáctanos</h3>
            <div class="input-container">
              <input placeholder="Nombre completo" type="text" name="name" class="input" v-model="form.fullname" />
            </div>
            <div class="input-container">
              <input placeholder="Email" type="email" name="email" class="input" v-model="form.email" />
            </div>
            <div class="input-container">
              <input placeholder="Teléfono" type="tel" name="phone" class="input" v-model="form.number" />
            </div>
            <div class="input-container textarea">
              <textarea placeholder="Mensaje..." name="message" class="input" v-model="form.descripcion"></textarea>
            </div>
            <input @click="sendForm" type="button" value="Enviar" class="btn" />
          </form>
        </div>
      </div>
    </div>
    </body>
</template>


<style scoped>
::placeholder {
  color: #fff;
}

.body-contact {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fafafa;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .form__content {
    max-height: auto;
    width: 100%;
    max-width: 1120px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    z-index: 999;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  
  .contact-form {
    background-color: #F8853E;
    position: relative;
  }
  
  .circle {
    border-radius: 50%;
    background: linear-gradient(135deg, transparent 20%, #FDD995);
    position: absolute;
  }
  
  .circle.one {
    width: 130px;
    height: 130px;
    top: 130px;
    right: -40px;
  }
  
  .circle.two {
    width: 80px;
    height: 80px;
    top: 10px;
    right: 30px;
  }
  
  .contact-form:before {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: #FAAF6A;
    transform: rotate(45deg);
    top: 50px;
    left: -13px;
  }
  
  .contact__form {
    padding: 2.3rem 2.2rem;
    z-index: 10;
    overflow: hidden;
    position: relative;
  }
  
  .title {
    color: #fff;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 1;
    margin-bottom: 0.7rem;
  }
  
  .input-container {
    position: relative;
    margin: 1rem 0;
  }
  
  .input {
    width: 100%;
    outline: none;
    border: 2px solid #fafafa;
    background: none;
    padding: 0.6rem 1.2rem;
    color: #fff;
    font-weight: 500;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    border-radius: 25px;
    transition: 0.3s;
  }
  
  textarea.input {
    padding: 0.8rem 1.2rem;
    min-height: 150px;
    border-radius: 22px;
    resize: none;
    overflow-y: auto;
  }
  
  .input-container label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    padding: 0 0.4rem;
    color: #fafafa;
    font-size: 0.9rem;
    font-weight: 400;
    pointer-events: none;
    z-index: 900;
    transition: 0.5s;
  }
  
  .input-container.textarea label {
    top: 1rem;
    transform: translateY(0);
  }
  
  .btn {
    width: 95px;
    padding: 0.6rem 1.3rem;
    background-color: #fff;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    font-weight: 600;
    color: #F8853E;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
  .btn:hover {
    background-color: transparent;
    color: #fff;
  }
  
  .input-container .contact-span {
    position: absolute;
    top: 0;
    left: 25px;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0 0.4rem;
    color: transparent;
    pointer-events: none;
    z-index: 500;
  }
  
  .input-container .contact-span:before,
  .input-container .contact-span:after {
    content: "";
    position: absolute;
    width: 10%;
    opacity: 0;
    transition: 0.3s;
    height: 5px;
    background-color: #F8853E;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-container .contact-span:before {
    left: 50%;
  }
  
  .input-container .contact-span:after {
    right: 50%;
  }
  
  .input-container.focus label {
    top: 0;
    transform: translateY(-50%);
    left: 25px;
    font-size: 0.8rem;
  }
  
  .input-container.focus .contact-span:before,
  .input-container.focus .contact-span:after {
    width: 50%;
    opacity: 1;
  }
  
  .contact-info {
    padding: 2.3rem 2.2rem;
    position: relative;
  }
  
  .contact-info .title {
    color: #F8853E;
  }
  
  .text {
    color: #333;
    margin: 1.5rem 0 2rem 0;
    text-align: left;
  }
  
  .information {
    display: flex;
    color: #555;
    margin: 0.8rem 0;
    align-items: center;
    font-size: 0.95rem;
  }

  .information p {
    margin-top: 10px;
    margin-left: 6px;
  }
  
  .social-media {
    padding: 2rem 0 0 0;
  }
  
  .social-media p {
    color: #333;
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(45deg, #FCC47F, #F8853E);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  .contact-info:before {
    content: "";
    position: absolute;
    width: 110px;
    height: 100px;
    border: 22px solid #F8853E;
    border-radius: 50%;
    bottom: -77px;
    right: 50px;
    opacity: 0.3;
  }
  
  .big-circle {
    position: absolute;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #FCC47F, #F8853E);
    bottom: 50%;
    right: 50%;
    transform: translate(-40%, 38%);
  }
  
  .big-circle:after {
    content: "";
    position: absolute;
    width: 360px;
    height: 360px;
    background-color: #fafafa;
    border-radius: 50%;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
  }
  
  .square {
    position: absolute;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(181%, 11%);
    opacity: 0.2;
  }

  ion-icon {
    position: relative;
    top: 5px !important;
    font-size: 24px;
}
  
  @media (max-width: 850px) {
    .form__content {
      grid-template-columns: 1fr;
      margin-top: 50px;
    }
  
    .contact-info:before {
      bottom: initial;
      top: -75px;
      right: 65px;
      transform: scale(0.95);
    }
  
    .contact-form:before {
      top: -13px;
      left: initial;
      right: 70px;
    }
  
    .square {
      transform: translate(140%, 43%);
      height: 350px;
    }
  
    .big-circle {
      bottom: 75%;
      transform: scale(0.9) translate(-40%, 30%);
      right: 50%;
    }
  
    .text {
      margin: 1rem 0 1.5rem 0;
    }
  
    .social-media {
      padding: 1.5rem 0 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .contact-section {
      margin-top: 100px;
    }

    .container {
      padding: 1.5rem;
    }
  
    .contact-info:before {
      display: none;
    }
  
    .square,
    .big-circle {
      display: none;
    }
  
    .contact__form,
    .contact-info {
      padding: 1.7rem 1.6rem;
    }
  
    .text,
    .information,
    .social-media p {
      font-size: 0.8rem;
    }
  
    .title {
      font-size: 1.15rem;
    }
  
    .social-icons a {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  
    .icon {
      width: 23px;
    }
  
    .input {
      padding: 0.45rem 1.2rem;
    }
  
    .btn {
      padding: 0.45rem 1.2rem;
    }

    ion-icon {
      position: relative;
      top: 3px !important;
      font-size: 22px;
    }
  }
</style>