<script setup>
import {computed, onMounted, reactive, ref} from "vue";
import {post} from "../../services/apiReq";
import {show} from "../../services/notification";

const productos = ref([]);
let form = reactive({
  nombre: null,
  dni: null,
  email: null,
  telefono: null,
  direccion: null,
  codigoPostal: null,
  ciudad: null,
});

onMounted(async () => {
  let res = await post("/carrito/get");

  productos.value = res?.data?.productos;

  let data = localStorage.getItem("verificationData");
  if(data) form = reactive(JSON.parse(data));

  if (productos.value <= 0) location.href = '/tienda';

});

const goTo = () => {
  let verification = true;

  for (let key in form) {
    let current = form[key];
    if(current === '' || current === null || current?.length <= 0) {
      show("Debe completar todos los campos antes de continuar")
      verification = false;
      break;
    }
  }
  if(!verification) return;
  localStorage.removeItem("verificationData");
  localStorage.setItem("verificationData", JSON.stringify(form));

  location.href = "/verificacion/envio";
}

const Total = computed(() => {
  let price = 0;
  productos.value.forEach((x) => {
    price += parseInt(x?.precio) * parseInt(x?.cantidad);
  })
  return price;
})
</script>

<template>
    <body>
        <div class='checkout__container'>
            <h3 class='checkout-title'>Información de facturación / envios</h3>
            <form class='checkout-form'>
                <div class='form-group'>
                    <input type="text" placeholder='Introduzca su nombre completo' required v-model="form.nombre" />
                </div>
                <div class='form-group'>
                    <input type="number" placeholder='Introduzca su DNI' required v-model="form.dni"/>
                </div>
                <div class='form-group'>
                    <input type="email" placeholder='Introduzca su email' required v-model="form.email" />
                </div>
                <div class='form-group'>
                    <input type="number" placeholder='Número de teléfono' required v-model="form.telefono"/>
                </div>
                <div class='form-group'>
                    <input type="text" placeholder='Dirección ' required v-model="form.direccion"/>
                </div>
                <div class='form-group'>
                    <input type="number" placeholder='Código Postal' required v-model="form.codigoPostal"/>
                </div>
                <div class='form-group'>
                    <input type="text" placeholder='Ciudad' required v-model="form.ciudad"/>
                </div>

                <div class="billing__info">
                <h6>
                  Cantidad total: <span>{{ productos?.length }} productos</span>
                </h6>
                <h6>
                  Subtotal: <span>AR$ {{ Total }}</span>
                </h6>
                <h4>
                  Coste TOTAL: <span> AR$ {{ Total  }}</span>
                </h4>
                <button type="submit" class="checkout__btn auth__btn w-100">
                  <a @click.prevent="goTo" class='payment__link'>
                    Siguiente
                  </a>
                </button>
            </div>
            </form>

        </div>
    </body>
</template>

<style scoped>
    .checkout__container {
        margin: 20px 160px;
        padding: 80px 40px;
        display: flex;
        flex-direction: column;
    }

    .checkout-title {
        padding: 20px 0;
    }

    .form-group {
        padding: 10px 0;
    }

    .form-group input {
        padding: 10px;
        width: 100%;
        border: 1px solid #d6e5fb;
        font-size: 16px;
    }

    .form-group input:focus {
        outline: none;
    }

    .billing__info {
        margin-top: 20px;
        padding: 20px;
        background: #0a1d37;
        color: #fff;
        border-radius: 5px;
        width: 380px;
        height: 280px;
    }

    .billing__info h6, h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
    }

    .billing__info  h6 {
        margin-bottom: 20px;
    }

    .billing__info h4 {
        border-top: 1px solid rgba(221, 221, 221, 0.253);
        padding-top: 20px;
    }
    .checkout__btn {
        margin-top: 4em;
        padding: 8px;
        background-color: #fff;
        border: none;
        border-radius: 40px;
        font-weight: 600;
    }

    .checkout__btn:hover {
        transform: scale(1.05);
        transition: .3s ease-out;
    }

    .payment__link {
        color: #000 !important;
    }

    @media only screen and (max-width: 969px) {
        .checkout__container {
            margin: 20px 60px;
            padding: 60px 0px;
        }
    }

    @media only screen and (max-width: 530px) {
        .checkout__container {
            margin: 20px 10px;
            padding: 60px 0px;
        }
    }
</style>
